import headerImg from "../../Assets/Images/TentangKamiHeader.png"
import headerImg2 from "../../Assets/Images/header2.svg"
import ceritakami from "../../Assets/Images/Vertical Stepper/Steps.svg"
import ceritakami2 from "../../Assets/Images/Vertical Stepper/Steps-1.svg"
import section1RArm from "../../Assets/Images/rightarm.png"
import armentdot from "../../Assets/Images/arment-dot.svg"
import armentcircle from "../../Assets/Images/armentcircle.svg"
import sectionlast from "../../Assets/Images/tentangkami section1.svg"

import React from 'react'
import "../../index.css"
import Navbar from "../../Components/Navbar";
import Footbar from "../../Components/Footbar";


function App() {

    return (
        <div id={"top"} className={"relative lg:pb-28"}>
            <Navbar/>
            <div className={"relative flex flex-col max-w-screen-2xl mx-auto"} >
                <div className={"relative w-full flex"}>
                    <div className={"h-screen-1 lg:h-screen max-h-screen-1 px-5 lg:px-10 flex"}>
                        <div className={"my-24 lg:my-auto flex flex-col space-y-3"}>
                            <p className={"w-full lg:w-1/3 text-4xl leading-relaxed font-bold"}>Tentang Kami</p>
                            <p className={"w-full lg:w-5/12 text-sm leading-relaxed"}>
                                Danai menyediakan solusi layanan jasa keuangan dengan mengandalkan teknologi sebagai prasarana untuk menghadirkan solusi kepada masyarakat.
                            </p>
                        </div>
                    </div>

                    <img src={headerImg} alt={'Header Images'} className={"absolute w-12/12 lg:w-6/12 right-0 bottom-0 z-20"}/>
                    <img src={headerImg2} alt={'Header Images'} className={"absolute w-12/12 lg:w-7/12 right-0 bottom-0 z-10"}/>
                </div>

                <div className={"relative px-5 lg:px-10 py-10 bg-blue-danai w-full flex flex-col space-y-10"}>
                    <div className={"flex flex-col space-y-5"}>
                        <p className={"text-3xl lg:text-4xl leading-normal"}>
                            <span className={"font-bold text-white"}>Apa itu Danai?</span>
                        </p>
                        <p className={"text-white w-full lg:w-1/2 text-sm"}>
                            Danai didukung oleh jajaran Manajemen yang berpengalaman luas di bidang jasa keuangan. Seluruh jajaran Komisaris dan Direksi di Danai merupakan mantan manajemen senior di industri Perbankan dan regulatornya.

                            <br/><br/>
                            Pengalaman mereka sebelumnya sebagai praktisi dapat memberikan kepastian pelayanan praktik usaha terbaik yang diberikan kepada nasabah Danai.
                        </p>
                    </div>
                    <img src={section1RArm} alt={"arnament 1"} className={"hidden lg:flex w-48 absolute top-2 right-0"}/>
                </div>

                <div className={"relative px-5 lg:px-10 py-10 bg-white w-full flex flex-col space-y-10 lg:space-y-12"}>
                    <div className={"flex flex-col space-y-5"}>
                        <p className={"w-full lg:w-5/12 font-light text-3xl lg:text-4xl leading-relaxed"}>
                            <span className={"px-2 font-bold"}>Cerita Kami</span>
                        </p>
                    </div>
                    <div className={"flex flex-col lg:flex-row w-full space-x-0 lg:space-x-20 space-y-0"}>
                        <img className={"z-10 w-64 lg:w-auto mb-auto"} src={ceritakami} alt={"cerita kami"}/>
                        <img className={"z-10 w-96 lg:w-auto mb-auto"} src={ceritakami2} alt={"cerita kami"}/>
                    </div>

                    <img className={"opacity-30 lg:opacity-100 absolute -top-5 right-5 lg:right-10"} src={armentdot} alt={"cerita kami"}/>
                    <img className={"opacity-30 lg:opacity-100 absolute bottom-0 right-0"} src={armentcircle} alt={"cerita kami"}/>

                </div>

                <div className={"relative bg-blue-danai px-5 lg:px-10 w-full flex flex-col space-y-10"}>
                    <div className={"flex flex-col lg:flex-row space-y-10 py-10 lg:space-y-0 space-x-0 lg:space-x-20 w-full justify-between items-center"}>
                        <img src={sectionlast} alt={"section 3"} className={"w-full lg:w-1/2 h-auto"}/>
                        <div className={"flex flex-col space-y-5 h-fit"}>
                            <div className={"bg-white px-8 py-5 rounded-xl flex flex-col space-y-3"}>
                                <p className={"text-lg lg:text-3xl font-bold"}>
                                    Visi Penyelenggara
                                </p>
                                <p className={"text-sm"}>
                                    Kami adalah Perusahaan Pelayanan Keuangan Digital yang menjembatani kebutuhan masyarakat dengan mendukung prinsip inklusi keuangan dengan menggunakan teknologi sebagai sarana pelayanan
                                </p>
                            </div>
                            <div className={"bg-white px-8 py-5 rounded-xl flex flex-col space-y-3"}>
                                <p className={"text-lg lg:text-3xl font-bold"}>
                                    Misi Penyelenggara
                                </p>
                                <p className={"text-sm"}>
                                    Misi kami adalah menghadirkan pelayanan keuangan digital dengan memegang 5 prinsip, yaitu :
                                </p>
                                <ul className={"list-decimal list-inside text-xs space-y-1"}>
                                    <li>
                                        Deference (Patuh)
                                    </li>
                                    <li>
                                        Assured (Aman)
                                    </li>
                                    <li>
                                        Nimble (Cepat Tanggap)
                                    </li>
                                    <li>
                                        Aplikatif
                                    </li>
                                    <li>
                                        Intuitif
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footbar/>
        </div>
    );

}

export default App;

