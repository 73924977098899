import section1RArm from "../../Assets/Images/rightarm.png"

import React from 'react'
import "../../index.css"
import Navbar from "../../Components/Navbar";
import Footbar from "../../Components/Footbar";


function App() {

    return (
        <div id={"top"} className={"relative lg:pb-28"}>
            <Navbar/>
            <div className={"relative flex flex-col max-w-screen-2xl mx-auto"} >

                <div className={"mt-32 relative px-5 lg:px-10 py-16 bg-blue-danai w-full flex flex-col space-y-10"}>
                    <div className={"flex flex-col space-y-5"}>
                        <p className={"text-xl lg:text-3xl lg:text-4xl leading-normal"}>
                            <span className={"font-bold text-white"}>Kebijakan Privasi</span>
                        </p>
                    </div>
                    <img src={section1RArm} alt={"arnament 1"} className={"hidden lg:flex w-48 absolute -top-4 right-0"}/>
                </div>

                <div className={"relative px-5 lg:px-20 py-10 bg-white w-full flex flex-col space-y-10 lg:space-y-12"}>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify"}>
                        Kebijakan privasi ini menjelaskan bagaimana Danai mengumpulkan dan menggunakan data pribadi yang telah Anda kirimkan kepada kami. Privasi Anda dan perlindungan terhadap informasi personal anda yang sensitif sangatlah penting bagi kami. Karena kami mengumpulkan dan / atau menyimpan beberapa jenis informasi dari dan tentang anda, kami perlu menjelaskan kebijakan kami dan syarat dan kondisi seputar pendataan dan penggunaan informasi tersebut.
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                            1. Perilaku Pegawai
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Danai menerapkan komitmen penuh kepada integritas dan nilai-nilai perusahaan dan etika standard serta perilaku bisnis yang juga dituntut dari semua pegawai kami. Kode Etik Danai mengatur tata cara mengenai pengamanan informasi rahasia secara spesifik, termasuk di dalamnya informasi pelanggan. Secara umum, pedoman ini membatasi akses pegawai terhadap informasi rahasia dan membatasi penggunaan dan pengungkapan informasi terutama mengenai transaksi dan proses yang telah disetujui dan sedang berjalan. Jika kami mengetahui ada karyawan yang melanggar Kode Etik, tindakan korektif dapat diambil oleh perusahaan, termasuk pemecatan.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                            2. Verifikasi Praktisi Data
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Secara berkala, kegiatan dan praktek usaha kami akan ditelaah untuk memeriksa apakah sudah sesuai dengan prosedur dan kebijakan yang mengatur kerahasiaan informasi. Pemeriksaan ini dapat dilakukan oleh staf internal kami sendiri, jasa akuntan dan audit eksternal, dan pemerintah. Termasuk di dalamnya adalah penilaian terhadap perlindungan konsumen terkait dengan privasi.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                            3. Apa yang Danai lakukan dengan informasi pribadi Anda?
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Kami mengumpulkan informasi finansial dan pribadi dari anda sewaktu Anda menggunakan laman kami dan sebagian besar informasi didapatkan sewaktu Anda mendaftar dan mengunggah data anda. Kami juga mengumpulkan data dari akun media sosial yang Anda ijinkan untuk kami akses. Kami juga memperoleh data dari waktu Anda berada di laman kami. Data-data ini mencakup, namun tidak terbatas pada, komputer anda, informasi geografis, alamat IP, sumber rujukan, lama waktu anda di laman, periode kunjungan, page views dan klik. Kumpulan informasi ini sebagian besar digunakan untuk meningkatkan kinerja dan keamanan Platform.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                            4. Apa alasan Danai meminta informasi pribadi Anda?
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                           Kami mewajibkan semua pengguna untuk mengirimkan informasi dasar untuk memudahkan proses verifikasi identitas anda yang telah diatur oleh hukum negara, berkomunikasi anda, memastikan bahwa anda sudah berusia di usia legal dan mengurangi potensi untuk penipuan. Kami hanya akan menggunakan informasi pribadi untuk memberikan layanan kepada pengguna dan tidak akan berbagi data tersebut kepada pihak ketiga tanpa persetujuan dari pengguna kami. Kami meminta data tambahan opsional tetapi harus diisi untuk memungkinkan kami untuk memberikan layanan tambahan kepada pengguna. Hal ini termasuk menentukan kelayakan peminjam atas aplikasi pinjamannya melalui proses credit-scoring kami, membuat komunitas berbasis pengguna di platform kami dan melaksanakan kegiatan pengumpulan data yang diperlukan.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           5. Apakah data Saya aman?
                        </span>
                        <span className={"flex flex-col space-y-3 lg:space-y-5 pl-2 lg:pl-5"}>
                            <span className={"text-lg lg:text-2xl font-bold"}>
                            a. Akses
                        </span>
                            <span className={"text-justify pl-5 lg:pl-8"}>
                            Danai sangat berhati-hati dalam praktek bisnis kami agar data yang Anda kirimkan kepada kami aman. Hanya sejumlah staf kami yang memiliki akses ke data pengguna dan sebagian besar dari mereka hanya memiliki akses ke bagian tertentu dari data yang diperlukan untuk melakukan tugas-tugas mereka sebagai bagian dari layanan kami. Staf Kami tidak diperbolehkan untuk mentransfer atau membawa keluar data dari server kami.
                        </span>
                            <span className={"text-lg lg:text-2xl font-bold"}>
                            b. Penyimpanan
                        </span>
                            <span className={"text-justify pl-5 lg:pl-8"}>
                                Kami berkonsultasi dengan penyedia jasa keamanan finansial dan memberlakukan teknis perlindungan seperti enkripsi, dua faktor otentikasi, dan pemantauan berkala untuk memastikan bahwa informasi Anda dilindungi dan bahwa ada perlindungan untuk melindungi kepentingan para pengguna kami.
                            </span>
                            <span className={"text-lg lg:text-2xl font-bold"}>
                            c. Ulasan
                        </span>
                            <span className={"text-justify pl-5 lg:pl-8"}>
                                Langkah-langkah ini akan ditinjau dan disesuaikan secara berkala berdasarkan penilaian risiko yang sedang berlangsung atau pelanggaran keamanan, karena belum ada lembaga yang dapat menjamin sistem yang benar-benar aman. Kami juga merekomendasikan pengguna untuk mengambil langkah-langkah tertentu untuk membantu melindungi informasi mereka.
                            </span>
                            <span className={"text-lg lg:text-2xl font-bold"}>
                            d. Berbagi
                        </span>
                            <span className={"text-justify pl-5 lg:pl-8"}>
                                Pengguna dapat memilih untuk menambahkan pengguna lain ke daftar kontak mereka, atau membentuk dan bergabung di dalam kelompok dengan pengguna lain untuk mengidentifikasi dan merasa nyaman serta aman. Hal tersebut, dapat memungkinkan pengguna memperbolehkan beberapa informasi dari masing-masing untuk dilihat oleh anggota kelompok dan daftar kontak.
                            </span>
                            <span className={"text-lg lg:text-2xl font-bold"}>
                            e. Notifikasi
                        </span>
                            <span className={"text-justify pl-5 lg:pl-8"}>
                                Kami dapat mengirimkan notifikasi dari waktu ke waktu. Ini hal yang diperlukan bagi transaksi Anda pada platform kami dan juga konfirmasi yang diperlukan atas tindakan yang telah diambil. Hal ini untuk memastikan bahwa tidak ada orang lain yang membuat perubahan ke akun Anda tanpa sepengetahuan Anda. Kami juga akan mengirimkan update berkala pada akun Anda selama periode tertentu. Terkadang kami juga bisa mengirimkan pesan tentang update di platform kami yang mungkin adalah promosi atau perubahan di platform kami yang akan berpengaruh terhadap pengguna kami.
                            </span>
                            <span className={"text-lg lg:text-2xl font-bold"}>
                            f. Kasus Situasional
                        </span>
                            <span className={"text-justify pl-5 lg:pl-8"}>
                                Dalam kasus dari proses hukum, perintah pengadilan, atau proses, kami mungkin membagi data relevan kepada penegak hukum atau badan pemerintah seperti yang disyaratkan oleh hukum. Kami juga dapat membagi catatan pinjaman dan peminjam serta segala jenis pembayaran yang terekam dalam sistem Bank Sentral Indonesia.
                            </span>
                        </span>

                    </p>
                    <p className={"px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           6. Perubahan Kebijakan Privasi
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Karena perusahaan dan platform kami berevolusi untuk melayani pengguna kami dengan lebih baik, oleh karenanya kami terkadang membuat perubahan terhadap Kebijakan Privasi ini untuk kebutuhan yang memang diperlukan. Dalam hal perubahan substantif atau materiil, kami akan memberitahu Anda sebelum perubahan tersebut menjadi efektif.
                        </span>
                    </p>
                </div>


            </div>
            <Footbar/>
        </div>
    );

}

export default App;

