import './App.css';
import Home from './Pages/Home/index';
import { Routes, Route } from "react-router-dom";
import DampakSosial from "./Pages/DampakSosial";
import HubungiKami from "./Pages/HubungiKami";
import TentangKami from "./Pages/TentangKami";
import AnyPage from "./Pages/404.js"
import KebijakanPrivasi from "./Pages/KebijakanPrivasi";
import SyaratKetentuan from "./Pages/SyaratKetentuan";

function App() {
  return (

      <div className={"flex flex-col"}>
          <Routes>
              <Route path='*' element={<AnyPage />} />

              <Route path="/" element={<Home />}/>
              <Route path="Dampaksosial" element={<DampakSosial />}/>
              <Route path="Hubungikami" element={<HubungiKami />}/>
              <Route path="Tentangkami" element={<TentangKami />}/>
              <Route path="KebijakanPrivasi" element={<KebijakanPrivasi />}/>
              <Route path="SyaratKetentuan" element={<SyaratKetentuan />}/>
          </Routes>
      </div>
  );
}

export default App;
