import headerImg from "../../Assets/Images/header1.png"
import headerImg2 from "../../Assets/Images/header2.svg"
import section1Img from "../../Assets/Images/section1.png"
import section1RArm from "../../Assets/Images/rightarm.png"
import section3Img1 from "../../Assets/Images/section3.png"
import section3Img2 from "../../Assets/Images/section31.png"
import danaipersonal1 from "../../Assets/Images/danaipersonal 1.svg"
import danaipersonal2 from "../../Assets/Images/danaipersonal 2.svg"
import danaipersonal3 from "../../Assets/Images/danaipersonal 3.svg"
import danaipersonal4 from "../../Assets/Images/danaipersonal 4.svg"
import danaipersonal5 from "../../Assets/Images/danaipersonal 5.svg"
import danaipersonal6 from "../../Assets/Images/danaipersonal 6.svg"
import danaipersonal7 from "../../Assets/Images/danaipersonal 7.svg"
import danaienterprise1 from "../../Assets/Images/DanaiEnterprise 1.svg"
import danaienterprise2 from "../../Assets/Images/DanaiEnterprise 2.svg"
import googlestore from "../../Assets/Images/Android.svg"
import playstore from "../../Assets/Images/Apple.svg"
import imgSection1 from "../../Assets/Images/2441 1.png"
import React from 'react'
import "../../index.css"
import Navbar from "../../Components/Navbar"
import Footbar from "../../Components/Footbar"

function App() {

    return (
        <div id={"top"} className={"relative lg:pb-28"}>
        <Navbar/>
            <div className={"relative flex flex-col max-w-screen-2xl mx-auto"} >
                <div className={"relative w-full flex"}>
                    <div className={"h-screen-1 lg:h-screen max-h-screen-1 px-5 lg:px-10 flex"}>
                        <div className={"my-24 lg:my-auto flex flex-col space-y-8"}>
                            <p className={"w-full lg:w-1/3 text-4xl font-bold"}>Semua Kebutuhan Dalam Satu Aplikasi</p>
                            <p className={"w-full lg:w-5/12 text-sm leading-relaxed"}>
                                Danai siap memenuhi semua kebutuhan layanan keuangan Anda. Nikmati kemudahan berbagai layanan keuangan di tangan Anda. Anda dapat merencanakan kebutuhan keuangan dalam satu aplikasi.
                            </p>
                        </div>
                    </div>

                    <img src={headerImg} alt={'Header Images'} className={"absolute w-12/12 lg:w-7/12 right-0 bottom-0 z-20"}/>
                    <img src={headerImg2} alt={'Header Images'} className={"absolute w-12/12 lg:w-7/12 right-0 bottom-0 z-10"}/>
                </div>

                <div className={"relative px-5 lg:px-10 py-10 bg-blue-danai w-full flex flex-col space-y-10"}>
                    <div className={"flex flex-col space-y-5"}>
                        <p className={"text-3xl lg:text-4xl leading-normal"}>
                            <span className={"font-light text-white"}>kenapa Danai</span><br/>
                            <span className={"font-bold text-white"}>menjadi pilihan terbaik</span>
                        </p>
                        <p className={"text-white w-full lg:w-1/2 text-sm"}>
                            Danai didukung oleh jajaran Manajemen yang berpengalaman luas di bidang jasa keuangan. Kami melihat terdapat kesenjangan yang cukup besar di Indonesia antara masyarakat yang menikmati layanan keuangan dan yang belum pernah merasakan layanan tersebut.
                        </p>
                    </div>
                    <div className={"flex flex-col lg:flex-row space-y-10 lg:space-y-0 space-x-0 lg:space-x-20 w-full justify-between"}>
                        <img src={section1Img} alt={"section 1"} className={"w-full lg:w-1/2 h-auto"}/>
                        <div className={"text-white flex flex-col space-y-5"}>
                            <p className={"text-white text-3xl font-bold"}>Perlu Kamu Ketahui</p>
                            <div className={"flex flex-col space-y-3"}>
                                <p className={"text-white text-lg lg:text-xl font-bold"}>
                                    Peminjam Dan Pemberi Pinjaman Prioritas Utama
                                </p>
                                <p className={"text-white text-sm"}>
                                    Memberikan pelayanan kepada Anda baik sebagai Peminjam atau Pemberi Pinjaman adalah prioritas kami.
                                </p>

                            </div>
                            <div className={"flex flex-col space-y-3"}>
                                <p className={"text-white text-lg lg:text-xl font-bold"}>
                                    Yang Terbaik Bagi Pelayanan Keuangan Anda
                                </p>
                                <p className={"text-white text-sm"}>
                                    Kami mengaplikasikan praktek industri terbaik dalam pengelolaan pelayanan keuangan Anda.
                                </p>

                            </div>
                            <div className={"flex flex-col space-y-3"}>
                                <p className={"text-white text-lg lg:text-xl font-bold"}>
                                    Resiko Yang Terukur
                                </p>
                                <p className={"text-white text-sm"}>
                                    Sebagai Pemberi Pinjaman Anda akan menikmati imbal hasil yang menarik dengan tahap resiko yang terukur.
                                </p>

                            </div>
                        </div>
                    </div>
                    <img src={section1RArm} alt={"arnament 1"} className={"hidden lg:flex w-48 absolute top-2 right-0"}/>
                </div>

                <div className={"relative px-5 lg:px-10 py-10 bg-white w-full flex flex-col space-y-10"}>
                    <div className={"flex flex-col space-y-5"}>
                        <p className={"w-full lg:w-5/12 mx-auto text-left lg:text-center font-light text-3xl lg:text-4xl leading-relaxed"}>
                            Lengkapi semua kebutuhanmu bersama
                            <span className={"px-2 font-bold"}>Danai</span>
                        </p>
                        <p className={"w-full lg:w-7/12 mx-auto text-left lg:text-center text-sm"}>
                            Tidak perlu khawatir dengan semua kebutuhanmu, Danai dapat memberikan kemudahan dalam pendanaan, transaksi, dan pengelolaan portofolio kapanpun dan di manapun.
                        </p>
                    </div>

                </div>

                <div className={"relative px-5 lg:px-10 py-10 w-full flex flex-col space-y-10"}>
                    <div className={"flex flex-col lg:flex-row space-y-10 lg:space-y-0 space-x-0 lg:space-x-20 w-full justify-between"}>
                        <img src={section3Img1} alt={"section 3"} className={"w-full lg:w-1/2 h-auto"}/>
                        <div className={"flex flex-col space-y-5"}>
                            <div className={"my-auto flex flex-col space-y-3"}>
                                <p className={"text-lg lg:text-3xl font-bold"}>
                                    Danai Personal
                                </p>
                                <p className={"text-sm"}>
                                    Untuk kamu yang ingin melakukan pendanaan, membangun asetmu, memenuhi kebutuhan keuangan, hingga melakukan pembayaran tagihan apapun dapat dilakukan dalam satu genggaman. Danai siap membantu semua transaksimu dalam satu aplikasi.
                                </p>
                                <div className={"grid grid-cols-4 lg:grid-cols-5 gap-5"}>
                                    <img src={danaipersonal1} alt={"danaipersonal1"}/>
                                    <img src={danaipersonal2} alt={"danaipersonal2"}/>
                                    <img src={danaipersonal3} alt={"danaipersonal3"}/>
                                    <img src={danaipersonal4} alt={"danaipersonal4"}/>
                                    <img src={danaipersonal5} alt={"danaipersonal5"}/>
                                    <img src={danaipersonal6} alt={"danaipersonal6"}/>
                                    <img src={danaipersonal7} alt={"danaipersonal7"}/>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className={"flex flex-col-reverse lg:flex-row space-y-10 lg:space-y-0 space-x-0 lg:space-x-20 w-full justify-between"}>
                        <div className={"flex flex-col space-y-5"}>
                            <div className={"my-auto flex flex-col space-y-3"}>
                                <p className={"text-lg lg:text-3xl font-bold"}>
                                    Danai Enterprise
                                </p>
                                <p className={"text-sm"}>
                                    Untuk kamu yang ingin mengembangkan bisnis dan perusahaan atau meningkatkan kualitas SDM, kami Danai siap membantu semua urusan transaksi juga manajemen perusahaan.
                                </p>
                                <div className={"grid grid-cols-4 lg:grid-cols-5 gap-5"}>
                                    <img src={danaienterprise1} alt={"danaienterprise1"}/>
                                    <img src={danaienterprise2} alt={"danaienterprise2"}/>
                                </div>

                            </div>
                        </div>
                        <img src={section3Img2} alt={"section 3 2 1"} className={"pb-10 lg:pb-0 w-full lg:w-1/2 h-auto"}/>

                    </div>
                </div>

                <div id={"downloadnow"} className={"flex bg-blue-danai text-white py-10 justify-center flex-col relative trigger-item-1-wrapper"}>
                    <div className="z-20 px-8 lg:px-28 flex flex-col lg:flex-row space-x-0 lg:space-x-20">
                        <div className={"flex-1 flex"}>
                            <img className={"mx-auto object-center object-cover"} src={imgSection1} alt={"img section 2"}></img>
                        </div>
                        <div className={"my-10 lg:my-auto flex-1 flex flex-col space-y-8"}>
                            <p className={"text-white tracking-wider leading-normal text-xl lg:text-3xl font-light font-montserrat flex flex-col"}>
                                Download Sekarang
                                <br/>
                                <span className={"pt-1 font-bold"}>
                                Solusi cepat & tepat untuk memenuhi kebutuhan keuangan
                            </span>
                            </p>
                            <div className={"flex flex-row space-x-5 font-lato"}>
                                <a href={"https://play.google.com/store/apps/details?id=com.adiwisista.project"}>
                                    <img src={googlestore} alt={"download"}/>
                                </a>
                                <a href={"https://apps.apple.com/id/app/danai-id-p2p-lending/id1503276449"}>
                                    <img src={playstore} alt={"download"}/>
                                </a>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
            <Footbar/>
        </div>
    );

}

export default App;

