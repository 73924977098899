import bg404 from '../Assets/Images/bg404.svg'
import logocolor from '../Assets/Images/Logo danai.id.svg'
import React from 'react'
import "../index.css"
import {Link} from "react-router-dom";


function App() {

    return (
        <div className={"relative flex flex-col w-full h-screen-1 lg:h-screen mx-auto"} style={{backgroundImage: `url(${bg404})`, backgroundRepeat:'no-repeat', backgroundSize:'cover' }} >
            <Link to={"/"}>
                <img alt={"danai logo"} className={"cursor-pointer w-32 absolute top-5 left-5"} src={logocolor}/>
            </Link>
            <div className={"m-auto flex flex-col space-y-5"}>
                <div className={"flex flex-col space-y-10"}>
                    <p className={"text-10xl text-center text-blue-danai"} style={{fontWeight: "700", lineHeight: "55px"}}>
                        404
                    </p>
                    <p className={"text-4xl text-center"} style={{ fontWeight: "700", lineHeight: "40px", color: "#A2CD3A"}}>
                        Page Not Found
                    </p>
                </div>
                <p className={"px-8 lg:px-0 text-xs lg:text-sm text-center text-blue-danai"} style={{ fontWeight: "400", lineHeight: "19px"}}>
                    Maaf, halaman yang Anda akses tidak ada.
                    <br/>
                    Silakan hubungi
                    <a href={"mailto:support@adiwisista.com"}>
                        <span className={"px-1 cursor-pointer font-bold text-green-danai underline"}>
                            tim support kami
                        </span>
                    </a>
                    atau kembali ke
                    <Link to={"/"}>
                        <span className={"px-1 cursor-pointer font-bold text-green-danai underline"}>
                            halaman utama page
                        </span>
                    </Link>
                    .
                </p>
            </div>
            <p className={"absolute bottom-3 text-center text-2xs w-full h-fit"}>
                © 2022 PT Adiwisista Financial Teknologi. All Rights Reserved.
            </p>
        </div>
    );

}

export default App;

