import headerimg from "../../Assets/Images/DampakSosial/Group 22289.png"
import dampak1 from "../../Assets/Images/DampakSosial/image 47.png"
import dampak2 from "../../Assets/Images/DampakSosial/image 48.png"
import dampak3 from "../../Assets/Images/DampakSosial/image 49.png"
import dampak4 from "../../Assets/Images/DampakSosial/image 50.png"
import dampak5 from "../../Assets/Images/DampakSosial/image 51.png"
import Navbar from "../../Components/Navbar";
import React from "react";
import Footbar from "../../Components/Footbar";



function Home() {
    return (
        <div className={"relative lg:pb-0"}>
            <Navbar/>
            <div className={"flex flex-col h-auto pt-24 lg:pt-0 lg:h-screen max-w-screen-2xl w-full mx-auto relative"}>
                <div className="z-20 px-8 lg:px-16 h-auto flex flex-col-reverse lg:flex-row my-auto lg:mt-20 2xl:my-auto ">
                <div className={"flex-1 flex flex-col my-10 lg:my-0 space-y-10 lg:space-y-16"}>
                    <div className={"flex flex-col my-0 lg:my-0 space-y-5 lg:space-y-8"}>
                        <p className={"text-2xl lg:text-4xl space-x-3 leading-normal font-lato"}>
                            <span className={"font-bold"}>
                              Dampak Sosial
                            </span>
                        </p>
                        <div className={"flex flex-col space-y-5"}>
                            <p className={"text-sm flex-1 lg:w-11/12"}>
                                Kami mendukung terciptanya produktivitas dan pekerjaan yang layak bagi semua pekerja, lalu membangun infrastruktur yang tangguh, mendukung industrialisasi yang inklusif dan berkelanjutan dan membatu perkembangan inovasi.
                                <br/>
                                <br/>
                                Kami juga mendukung terciptanya pola konsumsi dan produksi yang berkelanjutan, menguatkan ukuran implementasi dan merevitalisasi kemitraan global untuk pembangunan yang berkelanjutan.
                                <br/>
                                <br/>
                                Dan kami juga selalu memastikan pola konsumsi dan produksi yang berkelanjutan, menguatkan ukuran implementasi dan merevitalisasi kemitraan global untuk pembangunan yang berkelanjutan.
                            </p>
                        </div>
                    </div>
                    <div className={"flex flex-row w-full justify-between lg:justify-start lg:w-auto space-x-0 lg:space-x-5"}>
                        <img alt={"dampak sosial"} className={"w-12 lg:w-auto"} src={dampak1}/>
                        <img alt={"dampak sosial"} className={"w-12 lg:w-auto"} src={dampak2}/>
                        <img alt={"dampak sosial"} className={"w-12 lg:w-auto"} src={dampak3}/>
                        <img alt={"dampak sosial"} className={"w-12 lg:w-auto"} src={dampak4}/>
                        <img alt={"dampak sosial"} className={"w-12 lg:w-auto"} src={dampak5}/>
                    </div>
                </div>
                <div className={"flex-1 flex"}>
                    <img alt={"header dampak sosial"} className={"mx-auto my-auto"} src={headerimg}/>
                </div>

            </div>
            </div>
            <Footbar/>
        </div>
    );
}

export default Home;
