import section1RArm from "../../Assets/Images/rightarm.png"

import React from 'react'
import "../../index.css"
import Navbar from "../../Components/Navbar";
import Footbar from "../../Components/Footbar";


function App() {

    return (
        <div id={"top"} className={"relative lg:pb-28"}>
            <Navbar/>
            <div className={"relative flex flex-col max-w-screen-2xl mx-auto"} >

                <div className={"mt-32 relative px-5 lg:px-10 py-16 bg-blue-danai w-full flex flex-col space-y-10"}>
                    <div className={"flex flex-col space-y-5"}>
                        <p className={"text-xl lg:text-3xl lg:text-4xl leading-normal"}>
                            <span className={"font-bold text-white"}>Syarat Dan Ketentuan</span>
                        </p>
                    </div>
                    <img src={section1RArm} alt={"arnament 1"} className={"hidden lg:flex w-48 absolute -top-4 right-0"}/>
                </div>

                <div className={"relative px-5 lg:px-20 py-10 bg-white w-full flex flex-col space-y-10 lg:space-y-12"}>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify"}>
                        Selamat datang di Danai! Terima kasih telah mengunjungi www.danai.com Sebelum menggunakan, mengakses atau memanfaatkan Platform ini, pastikan Anda sudah membaca dengan baik seluruh Syarat dan Ketentuan Umum ini.
                        <br/>
                        <br/>
                        Syarat dan Ketentuan Umum ini berisi mengenai aturan, pedoman, panduan, pemberitahuan, kebijakan dan instruksi yang berkaitan dengan penggunaan Platform beserta Layanan yang terdapat dalam Platform. Dengan tetap mengakses dan menggunakan Platform ini Anda menyatakan bahwa Anda telah membaca, memahami dan menyetujui setiap dan seluruh isi Syarat dan Ketentuan Umum ini.
                        <br/>
                        <br/>
                        Kami akan mempublikasikan setiap perubahan atau amandemen yang bersifat substansial dari Syarat dan Ketentuan Umum ini (apabila ada) melalui Platform dan Anda diwajibkan untuk membaca dengan baik setiap perubahan atau amandemen tersebut sehingga apabila Anda tetap menggunakan, mengakses atau memanfaatkan Platform, Anda dianggap telah mengetahui, memahami dan menyetujui perubahan atau amandemen tersebut.
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                            1. Definisi
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                           Kecuali konteksnya menentukan lain, istilah-istilah dalam Syarat dan Ketentuan Pengguna di bawah ini akan memiliki arti sebagai berikut:
                            <br/><br/>
                            a. Perusahaan atau Kami adalah PT Lunaria Annua Teknologi suatu perusahaan yang menyediakan Platform dalam rangka melaksanakan kegiatan pinjaman secara peer to peer (peer to peer lending).
                            <br/><br/>
                            b. Layanan adalah jasa penyediaan ruang yang disediakan oleh Perusahaan pada Platform untuk mempertemukan Pendana dan Peminjam dalam rangka melaksanakan kegiatan pinjam meminjam uang berbasis teknologi informasi (peer–to–peer lending).
                            <br/><br/>
                            c. Pendana adalah Pengguna yang menggunakan Layanan yang bermaksud untuk memberikan Pinjaman melalui Platform.
                            <br/><br/>
                            d. Peminjam adalah setiap Pengguna yang menggunakan Layanan yang bermaksud untuk menerima Pinjaman melalui Platform.
                            <br/><br/>
                            e. Pengguna adalah setiap orang yang mengunjungi, mengakses dan/atau menggunakan Platform.
                            <br/><br/>
                            f. Target Pinjaman adalah aplikasi Pinjaman dari Peminjam yang ditempatkan di dalam Platform untuk ditawarkan kepada Pendana.
                            <br/><br/>
                            g. Klasifikasi Target Pinjaman adalah kategori Target Pinjaman berdasarkan nilai risiko dan bunga.
                            <br/><br/>
                            h. Pinjaman adalah dana yang diberikan oleh Pendana untuk ditujukan kepada Peminjam melalui Rekening Danai.
                            <br/><br/>
                            i. Jangka Waktu Pengumpulan Target Pinjaman adalah suatu periode tertentu dalam rangka pengumpulan dana Pinjaman oleh satu Pendana atau lebih terhadap suatu Target Pinjaman, yang akan ditentukan masing-masing di dalam Perjanjian Kredit dan dicantumkan di dalam deskripsi Target Pinjaman.
                            <br/><br/>
                            j. Platform adalah laman Danai di www.Danai.com
                            <br/><br/>
                            k. Rekening Danai adalah rekening khusus untuk transaksi peer to peer lending dalam bentuk escrow account yang di dalamnya memuat virtual account milik Pengguna.
                            <br/><br/>
                            l. Perjanjian Kredit adalah perjanjian pinjam meminjam antara Pendana dan Peminjam sehubungan dengan Pinjaman dalam rangka penyediaan Layanan yang diberikan oleh Perusahaan.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                            2. Ketentuan Umum
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Dengan mengakses dan menggunakan Layanan sebagai Pendana, Anda menyatakan bahwa Anda telah membaca, memahami dan sepakat untuk terikat pada Syarat dan Ketentuan Umum dan Syarat dan Ketentuan Pengguna.
                            <br/><br/>
                            Dalam hal terdapat pertentangan atau perbedaan penafsiran antara Syarat dan Ketentuan Umum dengan Syarat dan Ketentuan Pengguna, maka yang berlaku bagi Anda adalah Syarat dan Ketentuan Pengguna ini.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                            3. Aturan Operasional Pengguna
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Pengguna hanya dapat menggunakan Layanan pada Platform melalui akun Pengguna. Pengguna memahami bahwa dalam hal ini untuk dapat memiliki akun pada Platform ini Pengguna harus melakukan registrasi terlebih dahulu..
                            <br/><br/>

                            Alur kegiatan operasional sebagai Pengguna adalah sebagai berikut:
                            <br/><br/>

                            Syarat dan Ketentuan Umum
                            Gambaran umum mengenai langkah-langkah yang perlu anda perhatikan sebagai Pengguna adalah sebagai berikut:
                            <br/><br/>

                            a. Pengguna akan melakukan pendaftaran/registrasi terlebih dahulu pada Platform dengan mengisi data pribadi dan informasi lain yang diperlukan. Pengguna menjamin bahwa data pribadi dan informasi yang Pengguna ajukan adalah benar, akurat, lengkap, terbaru dan tidak menyesatkan.
                            <br/><br/>

                            b. Pengguna sebagai Pendana dapat memilih Target Pinjaman yang tertera pada Platform.
                            <br/><br/>

                            c. Satu Target Pinjaman dapat didanai oleh beberapa Pendana.
                            <br/><br/>

                            d. Syarat minimum nilai Pinjaman adalah sebesar Rp 100.000,- (seratus ribu Rupiah) per Pinjaman dan kelipatan Pinjaman adalah sebesar Rp 100.000,- (seratus ribu Rupiah).
                            <br/><br/>

                            e. Tenor pengembalian Pinjaman beserta bunga ditentukan dalam deskripsi masing-masing Target Pinjaman.
                            <br/><br/>

                            f. Pinjaman menggunakan mata uang Rupiah.
                            <br/><br/>

                            g. Terdapat beberapa jenis Klasifikasi Target Pinjaman berdasarkan tingkat risiko Peminjam dan Target Pinjaman, yaitu tingkat risiko A sampai dengan E. Tingkat risiko A adalah Klasifikasi Target Pinjaman dengan risiko yang paling rendah (paling baik) dan tingkat risiko E adalah Klasifikasi Target Pinjaman dengan risiko paling besar (paling tidak baik). Namun, Perusahaan dapat mengganti tingkatan Klasifikasi Target Pinjaman per masing-masing tingkat risiko, mengingat Perusahaan akan terus meningkatkan layanan dalam memberikan informasi yang sebenar-benarnya bagi Pengguna. Perusahaan akan memberitahukan segala perubahan terhadap Klasifikasi Target Pinjaman melalui Platform.
                            <br/><br/>

                            h. Perusahaan mentransfer pengembalian.
                            <br/><br/>

                            i. Pendana akan mentransfer Pinjaman melalui Rekening Danai dimana Perusahaan akan meneruskan Pinjaman tersebut kepada Peminjam, dengan ketentuan di bawah ini:
                            <br/><br/>

                            Proses pendanaan Target Pinjaman telah mencapai 100% (dana yang dibutuhkan telah terpenuhi) atau setidaknya telah mencapai 80% pada saat jangka waktu pendanaan Target Pinjaman berakhir.
                            Proses pendanaan Target Pinjaman telah mencapai 100% (dana yang dibutuhkan telah terpenuhi) atau setidaknya telah mencapai 80% pada saat jangka waktu pendanaan Target Pinjaman berakhir.
                            <br/><br/>
                            j. Peminjam akan mengembalikan Pinjaman dalam bentuk cicilan per bulan (beserta bunga) yang akan dibayarkan ke Rekening Danai. Lalu, Perusahaan akan mendistribusikan pengembalian Pinjaman tersebut kepada masing-masing Pendana.
                            <br/><br/>

                            k. Pendana dapat menarik pengembalian Pinjaman dari Rekening Danai setiap saat.
                            <br/><br/>

                            l. Pengguna menanggung pajak-pajak yang timbul sesuai peraturan perundangan-undangan tentang perpajakan yang berlaku.
                            <br/><br/>

                            Dalam menggunakan Layanan sebagai Pendana, Pengguna memahami dan menyetujui bahwa:
                            <br/><br/>

                            Perusahaan tidak membuat pernyataan, garansi, janji atau jaminan bahwa setiap dana yang dipinjamkan kepada Peminjam melalui Platform ini akan dilunasi baik secara keseluruhan atau sebagian;
                            Informasi yang diberikan oleh Perusahaan sehubungan dengan Layanan dan Pinjaman adalah upaya Perusahaan untuk memberikan informasi mengenai Pinjaman yang sejelas-jelasnya dan bukan merupakan usaha Perusahaan untuk mempengaruhi keputusan Anda terkait pemberian Pinjaman;
                            Anda akan melakukan analisis risiko secara mandiri atau menggunakan jasa pihak ketiga yang Anda tunjuk atas setiap keputusan yang Anda lakukan melalui Platform.
                            Perusahaan berhak untuk mendapatkan pembayaran dari Pendana untuk jasa penyediaan Platform kepada Anda sebesar 1% (satu persen) terhadap setiap pengembalian Pinjaman oleh Peminjam.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           4. Kebijakan T+2
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            a. Terhadap Pengguna akan berlaku Kebijakan T+2, dimana Perusahaan hanya dapat menyimpan dana yang telah dikirimkan Pengguna selama 2 (dua) hari. Untuk mempermudah Pengguna, setelah 2 (dua) hari dana tersebut kemudian akan secara otomatis diinvestasikan ke dalam produk Reksa Dana yang dikelola oleh PT Mandiri Manajemen Investasi (“Mandiri Investasi”). Namun jika Pengguna tidak menyetujui diterapkannya investasi otomatis tersebut terhadapnya, maka Pengguna harus menarik dananya yang terdapat dalam Virtual Account miliknya setiap 2 (dua) hari. Dalam hal ini, Pengguna dianggap menyetujui dilakukannya investasi atas dana apabila dana tersebut belum/tidak ditarik dalam jangka waktu 2 (dua) hari. Ketentuan lebih lanjut mengenai Kebijakan T+2 dapat dilihat disini dan ketentuan informasi mengenai produk dan Mandiri Investasi dapat dilihat disini.
                            <br/><br/>
                            b. Atas investasi Reksa Dana tersebut, Pengguna akan memperoleh rincian saldo, Nilai Aktiva Bersih dan riwayat investasi serta surat konfirmasi transaksi dan laporan bulanan pada Platform.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           5. Syarat Pengguna
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Dalam mengakses dan menggunakan Layanan sebagai Pengguna, Pengguna menyatakan dan menjamin bahwa:
                            <br/><br/>
                            a. Pengguna merupakan Warga Negara Indonesia dan berdomisili di Indonesia;
                            <br/><br/>

                            b. Pengguna berusia sekurang-kurangnya 21 (dua puluh satu) tahun dan cakap secara hukum untuk terikat pada Syarat dan Ketentuan Pengguna ini dan segala perjanjian lain dengan Perusahaan dan/atau pihak lainnya terkait penggunaan Layanan;
                            <br/><br/>

                            c. Uang yang Pengguna gunakan untuk pemberian Pinjaman (dalam hal Pengguna merupakan Pendana) bukan merupakan hasil pelanggaran hukum dan peraturan perundang-undangan yang berlaku;
                            <br/><br/>

                            d. Pengguna bersedia untuk memberikan kuasa kepada Perusahaan untuk menandatangani seluruh formulir aplikasi, dokumen, korespondensi dan hal-hal lain yang diperlukan dengan Peminjam dan/atau pihak lainnya dalam rangka pelaksanaan Layanan.
                            <br/><br/>

                            e. Apabila Pengguna sudah menikah dan tidak memiliki perjanjian pra-nikah, Pengguna telah mendapat persetujuan dari pasangan untuk menggunakan Layanan sebagai Pengguna.

                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           6. Registrasi
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Dalam melakukan pendaftaran/registrasi sebagai Pengguna melalui Platform, Pengguna menyatakan dan menjamin bahwa anda:
                            <br/><br/>

                            a. Menggunakan Platform dengan itikad baik;
                            <br/><br/>

                            b. Tidak memanfaatkan Platform untuk keperluan-keperluan yang bertentangan dengan hukum;
                            <br/><br/>

                            c. Memberikan informasi benar, akurat, lengkap, terbaru dan tidak menyesatkan mengenai Anda sebagaimana diminta oleh Perusahaan, termasuk namun tidak terbatas pada, informasi yang dimintakan dalam setiap formulir registrasi yang terdapat pada Platform;
                            <br/><br/>

                            d. Apabila Pengguna melakukan pendaftaran/registrasi atas nama sebuah organisasi, asosiasi atau perusahaan, baik berbadan hukum maupun tidak berbadan hukum, maka Pengguna menjamin bahwa Pengguna adalah orang yang berwenang untuk mewakili organisasi, asosiasi atau perusahaan tersebut. Anda juga menjamin bahwa Pengguna memberikan informasi yang benar, akurat, lengkap, terbaru dan tidak menyesatkan sebagaimana diatur dalam poin c di atas;
                            <br/><br/>

                            e. Menjaga keamanan password dan identifikasi akun Pengguna pada Platform;
                            <br/><br/>

                            f. Memelihara dan memperbaharui data registrasi dan segala informasi lainnya yang Pengguna berikan pada Perusahaan;
                            <br/><br/>

                            g. Memberitahu Perusahaan akan segala perubahan material pada informasi atau keadaan yang dapat mempengaruhi kelayakan penggunaan akun Pengguna pada Platform;
                            <br/><br/>

                            h. Bertanggung jawab secara penuh atas segala informasi yang diberikan kepada Perusahaan melalui Platform;
                            <br/><br/>

                            i. Bertanggung jawab secara penuh atas penggunaan serta segala tindakan yang Pengguna lakukan melalui akun Pengguna pada Platform.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           7. Keterbukaan Informasi
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Perusahaan tidak akan memberikan informasi tentang Pengguna kepada pihak-pihak lainnya. Apabila Pengguna lain (baik Peminjam maupun Pendana lain) dalam rangka pemberian Pinjaman meminta informasi mengenai anda selain yang terdapat pada Platform maka Perusahaan hanya dapat memberikan informasi tersebut setelah mendapatkan persetujuan Pengguna terlebih dahulu.
                            <br/><br/>

                            Dengan menggunakan Layanan sebagai Pendana, Pengguna berhak untuk:
                            <br/><br/>

                            a. memperoleh informasi mengenai calon Peminjam yang akan diberikan Pinjaman yang terdapat pada Perusahaan melalui Platform;
                            <br/><br/>

                            b. mengakses riwayat angsuran Pinjaman yang terdapat pada Perusahaan melalui Platform.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           8. Rekening Danai
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Untuk transaksi pinjam meminjam uang berbasis teknologi informasi (peer-to-peer lending) yang terjadi dalam Platform, Perusahaan akan menyediakan escrow account yang di dalamnya memuat virtual account milik Pengguna.
                            <br/><br/>

                            Perusahaan tidak akan menggunakan dana Pengguna yang tersimpan di dalam Rekening tersebut selain untuk tujuan yang disepakati dalam Perjanjian Pemberian Pinjaman.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           9. Layanan Perusahaan Kepada Pendana
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Pengguna dalam menggunakan Layanan sebagai Pendana berhak mendapatkan Layanan pada Platform sebagai berikut:
                             <br/><br/>

                            a. Melihat seluruh Target Pinjaman yang ada pada Platform dan menentukan Target Pinjaman yang akan Pengguna berikan Pinjaman;
                            <br/><br/>

                            b. Berkomunikasi dengan Pengguna lain. Namun, segala informasi yang Anda berikan kepada Pengguna lain tersebut, baik melalui Platform maupun tidak melalui Platform, akan menjadi tanggung jawab Anda dan bukan tanggung jawab Perusahaan;
                            <br/><br/>

                            c. Mengubah dan memperbaharui informasi Anda selaku Pendana yang tercantum pada Platform.
                            <br/><br/>

                            Selain itu, Pendana dapat setiap saat menginstruksikan Perusahaan melalui Platform untuk hal-hal sebagai berikut:
                            <br/><br/>

                            1. Membiarkan uang Pendana (dana Pinjaman dan/atau pengembalian dana Pinjaman beserta bunga) yang berada di Rekening Danai sebagai dana stand-by untuk Target Pinjaman lainnya paling lama 2 (dua) Hari Kerja. Ketentuan lanjut mengenai hal ini, harap mengacu pada Poin 4 Syarat dan Ketentuan Pengguna di atas tentang Kebijakan T+2;
                            <br/><br/>

                            2. Mendistribusikan uang Pendana (dana Pinjaman dan/atau pengembalian dana Pinjaman beserta bunga) yang berada di Rekening Danai untuk Target Pinjaman lainnya; atau
                            <br/><br/>

                            3. Mencairkan dan mentransfer uang Pendana (pengembalian dana Pinjaman beserta bunga) yang berada di Rekening Danai kepada rekening Pendana yang terdaftar. Mohon diperhatikan bahwa uang Pendana yang dicairkan sebagaimana dimaksud dalam huruf C di atas adalah sebagai berikut:
                            <br/><br/>

                            uang milik Pendana yang telah digunakan untuk mendanai Target Pinjaman, namun Target Pinjaman tersebut ditolak (karena tidak mencapai 80% dalam jangka waktu pendanaan Target Pinjaman berakhir);
                            <br/><br/>
                            merupakan uang yang mengendap di dalam Rekening Danai milik Pendana karena tidak digunakan untuk mendanai Target Pinjaman apapun; dan/atau
                            <br/><br/>
                            merupakan pengembalian dana Pinjaman.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           10. Kebijakan Privasi
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Kami menghormati perlindungan atas privasi Pengguna. Pengguna memahami bahwa Pengguna hanya dapat mengakses beberapa fitur pada Platform apabila Pengguna memberikan dan mengizinkan beberapa informasi dan/atau data kepada Perusahaan melalui Platform.
                            <br/><br/>

                            Pengguna memahami bahwa Pengguna telah mengizinkan Perusahaan untuk mengumpulkan informasi-informasi tentang Pengguna, termasuk namun tidak terbatas pada IP address dan hardware Pengguna sejak Pengguna mengunjungi dan mengakses Platform.
                            <br/><br/>

                            Perusahaan selalu meminta persetujuan Pengguna terlebih dahulu sebelum memberikan informasi mengenai Pengguna kepada pihak lainnya. Dalam hal Pengguna berkomunikasi dan memberikan informasi mengenai Pengguna kepada Pengguna lain, baik melalui Platform maupun tidak melalui Platform, Pengguna sepenuhnya bertanggung jawab atas hal tersebut dan bukan merupakan tanggung jawab Perusahaan.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           11. Ketentuan Pajak
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Pengguna memahami bahwa Pengguna memiliki kewajiban pajak untuk setiap transaksi yang Pengguna lakukan melalui Platform berdasarkan peraturan perundang-undangan yang berlaku. Perusahaan tidak memberikan nasihat pajak atau hukum kepada Pengguna sehubungan dengan Pinjaman.
                            <br/><br/>
                            Dalam hal Pengguna harus berkonsultasi dengan akuntan, penasihat pajak dan/atau penasihat hukum terkait penggunaan Platform dan atau Layanan, maka Pengguna akan berkonsultasi dengan akuntan, penasihat pajak dan/atau penasihat hukum Pengguna sendiri.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           12. Pernyataan Pemberian Kuasa
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Sehubungan dengan pelaksanaan transaksi pinjam meminjam uang berbasis teknologi informasi (peer-to-peer lending) ini, User bersedia untuk memberikan kuasa pada Perusahaan yang akan dinyatakan melalui Surat Kuasa Khusus nantinya.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           13. Perubahan atas Syarat dan Ketentuan Pengguna
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Perusahaan berhak untuk mengubah Syarat dan Ketentuan Pengguna atas kebijakannya sendiri dari waktu ke waktu. Segala perubahan atas Syarat dan Ketentuan Pengguna ini akan berlaku serta merta setelah Perusahaan mencantumkannya pada Platform. Perusahaan akan mencantumkan tanggal perubahan terakhir atas Syarat dan Ketentuan Pengguna pada halaman depan Platform dan menginformasikan kepada Pengguna melalui akun Pengguna. Pengguna dapat menghentikan keanggotaannya pada Platform apabila Pengguna tidak menyetujui Perubahan atas Syarat dan Ketentuan Pengguna.
                            <br/><br/>
                            Apabila setelah tanggal Perubahan Syarat dan Ketentuan Pengguna, Pengguna tidak memberikan respon dan/atau tetap melakukan transaksi pada Platform, maka Pengguna dianggap memahami dan menyetujui bahwa ketika Pengguna melanjutkan penggunaan Platform dan tetap melakukan kegiatan sebagai Pengguna setelah dilakukan perubahan atas Syarat dan Ketentuan Pengguna ini menjadikan Pengguna tunduk dan terikat pada Syarat dan Ketentuan Pengguna yang baru.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           14. Ganti Rugi
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Pengguna sepakat untuk melepaskan, membebaskan dan mengganti kerugian Perusahaan, afiliasinya, pemegang sahamnya, direktur, komisaris, karyawan, sub-kontraktor, pemasok, agen dari dan atas segala bentuk tuntutan, gugatan, klaim, kerugian, ganti rugi, termasuk biaya konsultan hukum, yang disebabkan oleh pelanggaran Syarat dan Ketentuan Pengguna ini oleh Pengguna dan ketentuan lain yang ditetapkan oleh Perusahaan
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           15. Pernyataan dan Jaminan Perusahaan
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Dengan ini Perusahaan menyatakan dan menjamin hal-hal sebagai berikut:
                            <br/><br/>
                            a. Perusahaan adalah penyedia Platform yang memfasilitasi kegiatan Peer-to-Peer Lending dan memberikan edukasi peminjaman kepada Pengguna.
                            <br/><br/>
                            b. Perusahaan bukan perusahaan pembiayaan karena konsep Peer-to-Peer Lending adalah Pinjaman langsung diberikan oleh Pengguna sebagai Pendana kepada Pengguna yang berstatus Peminjam, namun difasilitasi oleh Perusahaan sebagai penyedia Platform.
                            <br/><br/>
                            c. Perusahaan atau Peminjam tidak menerbitkan produk berupa Efek sebagaimana dimaksud dalam Undang-Undang No. 8 Tahun 1995 tentang Pasar Modal (“UU Pasar Modal”) sehingga tidak tunduk pada ketentuan mengenai Pernyataan Pendaftaran, Penawaran Umum dan ketentuan lainnya yang diatur dalam UU Pasar Modal.
                            <br/><br/>
                            d. Perusahaan atau Debitur tidak menerbitkan produk dalam bentuk Saham sebagaimana dimaksud dalam Undang-Undang Nomor 8 Tahun 1995 tentang Pasar Modal (“UU Pasar Modal”) sehingga tidak tunduk pada ketentuan Pernyataan Pendaftaran, Penawaran Umum dan ketentuan lain yang diatur dalam UU Pasar Modal.
                            <br/><br/>
                            e. Perusahaan menyatakan dan menjamin bahwa Rekening Danai yang digunakan Perusahaan untuk penerimaan, pembayaran dan pengembalian Pinjaman (Escrow Account) adalah rekening yang terpisah dengan rekening bank operasional Perusahaan.
                            <br/><br/>
                            f. Perusahaan hanya akan memotong biaya atas setiap pengembalian dan/aatu setiap pembayaran Pinjaman yang menjadi hak Perusahaan, dan tidak akan menggunakan dana yang tersimpan di dalam Rekening Danai.
                            <br/><br/>
                            g. Perusahaan menyatakan bahwa setiap transaksi bisnis dan kegiatan yang dilakukan sehubungan dengan penyelenggaraan layanan peer-to-peer lending ini tidak akan berhubungan dengan tindakan apapun yang mungkin melanggar peraturan perundang-undangan serta peraturan pelaksana terkait mengenai anti suap, anti korupsi, anti pencucian uang dan pencegahan pendanaan terorisme (“Undang-undang Nomor 31 Tahun 1999 tentang Pemberantasan Tindak Pidana Korupsi sebagaimana telah diubah dengan Undang-undang No. 20 Tahun 2001 tentang Perubahan Atas Undang-undang Nomor 31 Tahun 1999 tentang Pemberantasan Tindak Pidana Korupsi, Undang-undang Nomor 8 Tahun 2010 tentang Pencegahan dan Pemberantasan Tindak Pidana Pencucian Uang”)
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           16. Layanan dengan Upaya Terbaik oleh Perusahaan
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Pengguna memahami bahwa Perusahaan telah berupaya dan terus berupaya semaksimal mungkin untuk memberikan Layanan kepada Pengguna melalui Platform, termasuk namun tidak terbatas dalam memberikan tingkat risiko Klasifikasi Target Pinjaman berdasarkan analisa Perusahaan.
                            <br/><br/>
                            Pengguna juga memahami bahwa analisis Perusahaan dalam menentukan Klasifikasi Target Pinjaman berdasarkan nilai risiko dari setiap Pinjaman bukan merupakan jaminan dari Perusahaan atas kebenaran dan keakuratannya. Namun, Perusahaan terus berupaya semaksimal mungkin untuk meningkatkan akurasi dalam menentukan tingkat risiko tersebut.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           17. Layanan dan Keamanan Platform
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Perusahaan selalu memberikan perhatian dan usaha penuh dalam rangka memberikan keamanan dan layanan terbaik kepada Pengguna melalui Platform. Namun demikian, dalam hal terjadi masalah-masalah pada Platform di luar kendali Perusahaan, termasuk namun tidak terbatas pada kejahatan cyber, server bermasalah, malware, dsb., Anda selaku Pengguna Layanan sepakat untuk melepaskan dan membebaskan Perusahaan untuk bertanggung jawab terhadap hal-hal tersebut.
                            <br/><br/>
                            Dalam hal terjadi masalah-masalah pada Platform di luar kendali Perusahaan, termasuk namun tidak terbatas pada kejahatan cyber, server bermasalah dan malware, Perusahaan akan memperbaiki Platform dalam rangka menjaga keamanan data-data Pengguna dan Layanan. Untuk tujuan tersebut, dapat dimungkinkan bahwa Platform akan terhenti sementara dan/atau mengalami keterlambatan dalam proses Layanan. Pengguna sepakat untuk melepaskan dan membebaskan Perusahaan dari segala kerugian yang timbul sehubungan dengan proses perbaikan tersebut.
                            <br/><br/>
                            Anda selaku Pengguna bertanggung jawab untuk menjaga kerahasiaan informasi pribadi Pengguna yang dibutuhkan dalam proses verifikasi setiap akun Pengguna, baik melalui email maupun handphone Pengguna. Setiap kelalaian terhadap kerahasiaan informasi pribadi tersebut merupakan tanggung jawab masing-masing Pengguna, dan bukan merupakan tanggung jawab Perusahaan.
                        </span>
                    </p>
                    <p className={"border-b border-gray-300 px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           18. Batasan Tanggung Jawab
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Pengguna selaku Pendana memahami bahwa terdapat risiko gagal bayar oleh Peminjam dan memahami bahwa kegagalan tersebut sepenuhnya adalah risiko dari Pendana. Namun Perusahaan sesuai instruksi dari Pengguna selaku Pendana dapat melakukan tindakan penagihan (bekerja sama dengan penyedia jasa penagihan pihak ketiga) untuk menagih Pinjaman yang gagal bayar tersebut.
                            <br/><br/>
                            Sebagai penyedia Platform, tanggung jawab Perusahaan hanya sebatas memfasilitasi kebutuhan dan keluhan antar masing-masing Pengguna (antara Pendana dengan Peminjam). Perusahaan menyediakan fasilitas Customer Service dalam waktu kerja 09.00 s/d 18.00 Waktu Indonesia Barat (WIB) untuk melayani Pengguna dalam Layanan yang diberikan melalui Platform.
                        </span>
                    </p>
                    <p className={"px-2 pb-10 text-left leading-relaxed text-sm lg:text-justify flex flex-col space-y-5"}>
                        <span className={"text-xl lg:text-3xl font-bold"}>
                           19. Hukum yang Berlaku
                        </span>
                        <span className={"text-justify pl-5 lg:pl-8"}>
                            Syarat dan Ketentuan Pengguna ini diatur oleh dan ditafsirkan sesuai dengan hukum Republik Indonesia. Pengguna sepakat bahwa tindakan hukum apapun atau sengketa yang mungkin timbul dari, berhubungan dengan, penggunaan Platform akan diselesaikan secara eksklusif dalam yurisdiksi Pengadilan Negeri Jakarta Selatan Republik Indonesia
                        </span>
                    </p>

                </div>


            </div>
            <Footbar/>
        </div>
    );

}

export default App;

