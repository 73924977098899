
import React from 'react'
import "../../index.css"
import {Link} from "react-router-dom";
import Navbar from "../../Components/Navbar";
import * as FiIcons from "react-icons/fi"
import Footbar from "../../Components/Footbar";


function App() {

    return (
        <div className={"relative"}>
            <Navbar/>
            <div className={"-mb-28 lg:-mb-0 relative flex w-full max-w-screen-2xl pb-0 lg:pb-28 mx-auto"} >
                <div className={"mt-14 w-full h-auto lg:max-h-screen-1 flex flex-col lg:flex-row"}>
                    <div className={"w-full relative flex lg:w-1/2"}>
                        <iframe title={"map"} className={"relative w-full h-screen-1 max-h-screen-1 "} style={{}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3966.269149426569!2d106.807088!3d-6.2282025!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f5cb42390213%3A0x844c760199006224!2sDanai.id%20-%20P2P%20Lending%2C%20Pinjaman%20Karyawan%2C%20dan%20Pendanaan!5e0!3m2!1sen!2sid!4v1672022102025!5m2!1sen!2sid">
                        </iframe>
                        <div className={"flex flex-col space-y-1 shadow-md text-xs bg-white rounded-sm absolute px-4 py-2 left-2 bottom-2"}>
                            <p className={"font-bold text-sm text-left w-full"}>Call Center :</p>
                           <div className={"flex flex-row space-x-2 items-center"}>
                               <FiIcons.FiPhoneCall className={"text-blue-danai"}/>
                               <p className={"text-blue-danai"}>
                                   021 - 5010 3363
                               </p>
                           </div>
                        </div>
                    </div>
                    <div className={"px-5 lg:px-10 w-full h-screen-1 max-h-screen-1 lg:w-1/2"}>
                        <div className={"pt-5 lg:my-auto flex flex-col space-y-3"}>
                            <p className={"w-full text-3xl leading-relaxed font-bold"}>
                                Hubungi Kami
                            </p>
                            <p className={"w-full text-xs leading-relaxed"}>
                                Kami ada untuk membantu anda! Silahkan masukan pertanyaan atau masalah anda pada kolom di bawah atau kirim pesan anda melalui email di
                                <Link className={"text-blue-danai px-1"} to='/Hubungikami' onClick={() => window.location = 'mailto:customer.care@danai.id'}>
                                    customer.care@danai.id
                                </Link>.
                            </p>
                        </div>
                        <form action="mailto:customer.care@danai.id" method="get" encType="text/plain" className={"flex flex-col space-y-5 pt-5"}>
                            <label htmlFor="name" className={"flex flex-col space-y-3"}>
                                <p className={"text-blue-danai text-xs"}>Name</p>
                                <input type={"text"} name="name" id="name" className={"bg-gray-200 px-2 border-b-2 border-b-blue-400 rounded-sm py-1 outline-0"}>
                                </input>
                            </label>
                            <label htmlFor="email" className={"flex flex-col space-y-3"}>
                                <p className={"text-blue-danai text-xs"}>Email Address</p>
                                <input type={"text"} name="email" id="email" className={"bg-gray-200 px-2 border-b-2 border-b-blue-400 rounded-sm py-1 outline-0"}>
                                </input>
                            </label>
                            <label className={"flex flex-col space-y-3"}>
                                <p className={"text-blue-danai text-xs"}>Message</p>
                                <textarea name="comments" rows={8} cols={8} className={"bg-gray-200 px-2 border-b-2 border-b-blue-400 rounded-sm py-1 outline-0"}>
                            </textarea>
                            </label>
                            <button type="submit" name="submit" value="Send" className={"ml-auto text-white px-10 py-2 rounded-lg bg-blue-danai"}>
                                Submit
                            </button>

                        </form>
                    </div>
                </div>
            </div>
            <Footbar/>
        </div>
    );

}

export default App;