import * as FaIcons  from "react-icons/fa";
// import * as BsIcons  from "react-icons/bs";
import React, {useState} from 'react'
import "../Assets/Css/Navbar.css"
import { HashLink as Link } from 'react-router-hash-link';
import "../index.css"
import { SidebarData } from "./SidebarData";
import logo from "../Assets/Images/logowhite.svg"


export default function App() {
    const [sidebar, setSidebar] = useState(false)

    const showSidebar = () => setSidebar(!sidebar)
    return (
        <div id="navbaranimation"  className="shadow-bot mx-auto bg-blue-danai z-50 px-5 lg:px-10 py-3 fixed w-full flex">
            <div className={"mx-auto w-full max-w-screen-xl 2xl:max-w-screen-2xl flex flex-row justify-between "}>
                <div className={"w-4/12 relative absolute top-0 left-0 relative flex"}>
                    <Link to={"/#top"}>
                        <img id="logo" className={"top-20 left-20 w-24"} style={{zIndex:'99999999'}} alt={"hidden logo adiwisista"} src={logo}/>
                    </Link>
                </div>

                <div className='flex lg:hidden menu-bars my-auto'>
                    <FaIcons.FaBars onClick={showSidebar} id={"burger"} style={{ color: 'white !important' }} className={"text-white"} />
                </div>

                <div id={"textnav"} className={"flex-1 my-auto hidden lg:flex flex-row space-x-10"}>
                    <Link to={"/Tentangkami#top"}>
                        <p className={"text-sm text-white "}>Tentang Kami</p>
                    </Link>
                    <Link to={"/Hubungikami"}>
                        <p className={"text-sm text-white "}>Hubungi Kami</p>
                     </Link>
                    <Link to={"/Dampaksosial"}>
                        <p className={"text-sm text-white "}>Dampak Sosial</p>
                    </Link>
                </div>
                <div id={"download"} className={"hidden lg:flex w-4/12 my-auto justify-end text-white"}>
                    <Link to={"/#downloadnow"}>
                        <button className={"bg-white text-blue-danai rounded-md text-sm ml-auto px-5 py-1"}>
                            Download Now
                        </button>
                    </Link>

                </div>
            </div>
            <nav className={sidebar ? 'nav-menu flex py-5 flex-col active' : 'flex flex-col py-5 nav-menu'} style={{zIndex:10000}}>

                <div className={"flex flex-col space-y-8 py-5 px-10"}>
                    {SidebarData.map((item, index) => {
                        return (
                            <div key={index} className={"w-full mx-auto"}>
                                <Link onClick={showSidebar} className={"navbar-item border-b-2 border-white px-5 rounded-lg my-auto flex flex-row hover:bg-danai-blue-2 transition duration-300 h-10"} to={item.path}>
                                    <div className={"text-md my-auto text-white"}>{item.tittle}</div>
                                </Link>
                            </div>
                        );
                    })}

                    <Link to={"/#downloadnow"}>
                        <button onClick={showSidebar} className={"w-full bg-white text-blue-danai rounded-md text-sm ml-auto px-5 py-3"}>
                            Download Now
                        </button>
                    </Link>
                </div>

            </nav>

        </div>
    )
}

