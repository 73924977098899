
export const SidebarData = [
    {
        tittle:'Home',
        path:'/',

    },
    {
        tittle:'Tentang Kami',
        path:'/Tentangkami',

    },
    {
        tittle:'Hubungi Kami',
        path:'/Hubungikami',

    },
    {
        tittle:'Dampak Sosial',
        path:'/Dampaksosial',

    },

]