import React from "react";
import logofooter from "../Assets/Images/adiwisistalogo.svg";
import { HashLink as Link } from 'react-router-hash-link';

const { useLayoutEffect } = React;

export default function App() {

    useLayoutEffect(() => {

    });

    return (
        <div style={{zIndex:"99999999"}} className="lg:absolute bottom-0 shadow-top h-fit mx-auto bg-white z-50 px-5 lg:px-10 py-3 w-full flex">
            <div className={"mx-auto w-full max-w-screen-xl 2xl:max-w-screen-2xl flex flex-col-reverse lg:flex-row justify-between"}>
                <div className={"flex flex-col space-y-3"}>
                   <div className={"flex space-x-5 justify-between flex-row"}>
                       <img src={logofooter} alt={"logo Adiwisista"} className={"my-auto w-32"} />
                       <div className={"lg:hidden flex flex-row space-x-4"}>
                           <div className={"flex flex-col space-y-1"}>
                               <Link to={"/#top"}>
                                   <p className={"text-2xs lg:text-xs"}>Home</p>
                               </Link>
                               <Link to={"/Tentangkami"}>
                                   <p className={"text-2xs lg:text-xs"}>Tentang Kami</p>
                               </Link>
                               <Link to={"/Hubungikami"}>
                                   <p className={"text-2xs lg:text-xs"}>Hubungi Kami</p>
                               </Link>

                           </div>
                           <div className={"flex flex-col space-y-1"}>
                               <Link to={"/Dampaksosial"}>
                                   <p className={"text-2xs lg:text-xs"}>Dampak Sosial</p>
                               </Link>
                               <Link to={"/KebijakanPrivasi#top"}>
                                   <p className={"text-2xs lg:text-xs"}>Kebijakan Privasi</p>
                               </Link>
                               <Link to={"/SyaratKetentuan#topS"}>
                                   <p className={"text-2xs lg:text-xs"}>Syarat & Ketentuan</p>
                               </Link>
                           </div>
                       </div>
                   </div>

                    <p className={"mt-auto text-2xs mx-auto w-fit h-fit"}>
                        © 2022 PT Adiwisista Sarana Finansial. All Rights Reserved.
                    </p>
                </div>
                <div className={"hidden lg:flex flex-row space-x-10"}>
                    <div className={"flex flex-col space-y-1.5"}>
                        <Link to={"/#top"}>
                            <p className={"text-xs"}>Home</p>
                        </Link>
                        <Link to={"/Tentangkami"}>
                            <p className={"text-xs"}>Tentang Kami</p>
                        </Link>
                        <Link to={"/Hubungikami"}>
                            <p className={"text-xs"}>Hubungi Kami</p>
                        </Link>
                        <Link to={"/Dampaksosial"}>
                            <p className={"text-xs"}>Dampak Sosial</p>
                        </Link>
                    </div>
                    <div className={"flex flex-col space-y-1.5"}>
                        <Link to={"/KebijakanPrivasi#top"}>
                            <p className={"text-xs"}>Kebijakan Privasi</p>
                        </Link>
                        <Link to={"/SyaratKetentuan#top"}>
                            <p className={"text-xs"}>Syarat & Ketentuan</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

